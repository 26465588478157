/* Container to hold the image and text */
.contact-container {
    display: flex;
    justify-content: center; /* Spreads content across the container */
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Style for the right (text) section */
  .contact-text {
    width: 50%;
    padding-left: 20px;
  }
  
  .contact-text h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .contact-text p {
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
  
  .contact-info {
    margin-top: 20px;
    text-align: left; /* Aligns text to the left for better readability */
  }
  
  .contact-statement {
    margin: 10px 0;
    font-size: 1rem;
  }
  
  .contact-statement a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .contact-statement a:hover {
    text-decoration: underline;
  }

  .address {
    margin-top: 20px;
  }
  
  .address h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .address p {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .map-container iframe {
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column; /* Stack elements vertically */
      align-items: center;
      padding: 10px;
    }
  
    .contact-text {
      width: 100%; /* Full width for both sections */
      padding: 0;
    }
  
    .contact-text h2 {
      font-size: 1.5rem; /* Smaller heading */
      text-align: center;
    }
  
    .contact-text p {
      font-size: 1rem; /* Adjust font size for readability */
      text-align: center;
    }
  
    .contact-info {
      text-align: center;
    }
  
    .contact-statement {
      font-size: 0.9rem; /* Slightly smaller font for statements */
      margin: 8px 0;
    }
  }
